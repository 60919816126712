import { useAuthContext } from '../../../hooks/useAuthContext'; // Assuming you have an AuthContext
import firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';

const GoogleSignInButton = () => {
  const { dispatch } = useAuthContext();

  const handleGoogleSignIn = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      const result = await firebase.auth().signInWithPopup(provider);
      // The signed-in user info
      const user = result.user;

      // Dispatch login event to update the state with the new user info
      dispatch({ type: 'LOGIN', payload: user });

      console.log('Google sign-in successful:', user);
    } catch (error) {
      console.error('Google sign-in error:', error.message);
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <button onClick={handleGoogleSignIn} className="btn btn-danger">
        <i className="bi bi-google me-2"></i>Google Sign in
      </button>
    </div>
  );
};

export default GoogleSignInButton;
