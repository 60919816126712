// import './signup.css
import { React, useState } from 'react';
import { useSignup } from '../../hooks/useSignup';

//FIXME need login button to go to login
//TODO maybe totally redesign both login and signup

export default function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alias, setAlias] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const { signup, isPending, error } = useSignup();

  const handleSubmit = (e) => {
    e.preventDefault();
    signup(email, password, alias, firstName, surname);
  };

  const handleClear = (e) => {
    e.preventDefault();
    setEmail('');
    setPassword('');
    setAlias('');
    setFirstName('');
    setSurname('');
  };

  return (
    <div className="container">
      <div className="mt-4 m-2 row justify-content-center">
        <div className="col-sm-10 col-lg-8 card bg-light">
          <div className="row m-2">
            <div className="col-sm-12 col-md-6 p-3">
              <h1 className="pt-5 fw-bold">OtterBox</h1>
              <p>Paintball social network and event planner.</p>
            </div>
            <div className="col-sm-12 col-md-6 p-3">
              <h2 className="mb-3">Sign up</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3 row">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control col-6"
                      required
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First name"
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control col-6"
                      required
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                      placeholder="Surname"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={alias}
                    onChange={(e) => setAlias(e.target.value)}
                    placeholder="Alias"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email address"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="New Password"
                  />
                </div>
                {isPending && (
                  <button className="btn btn-primary" disabled>
                    Loading
                  </button>
                )}
                <div className="row gx-2">
                  <div className="col-6">{!isPending && <button className="btn btn-primary col-12">Sign up</button>}</div>
                  <div className="col-6">
                    {!isPending && (
                      <button
                        className="btn btn-secondary col-12"
                        onClick={(e) => {
                          handleClear(e);
                        }}
                      >
                        Clear
                      </button>
                    )}
                  </div>
                </div>
                {error && <div className="alert alert-warning">{error}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
