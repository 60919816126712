// import './navbar.css';
import { NavLink, Link } from 'react-router-dom';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';
import { React, useEffect, useState } from 'react';
import Avatar from '../avatar/Avatar';
import defaultProfile from '../../assets/defaultProfile.png';
import { useUpdateProfile } from '../../hooks/useUpdateProfile';

//FIXME menu doesnt close on logout press
//FIXME menu should be totally hidden for logged out users

export default function Header() {
  const { user, authIsReady } = useAuthContext();
  const { logout, isPending } = useLogout();

  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const { updateProfile } = useUpdateProfile();

  const handleSubmit = (e) => {
    let update = { displayName, password, email };
    e.preventDefault();
    updateProfile(update);
    setDisplayName('');
    setEmail('');
    setPassword('');
  };

  const handleCancel = (e) => {
    switch (e) {
      case 'dn':
        setDisplayName('');
        break;
      case 'pw':
        setPassword('');
        break;
      case 'em':
        setEmail('');
        break;

      default:
        break;
    }
  };
  return (
    <>
      <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top" style={{ top: '0px' }}>
        <div className="container m-1" style={{ maxWidth: '100%' }}>
          <div className="">
            <Link to="/" className="navbar-brand fw-bold text-body-secondary border-end border-3 pe-2">
              OtterBox{' '}
            </Link>
            {user && (
              <Link to={`/profile/${user.uid}`}>
                <span className="nav-avatar d-sm-inline-block">
                  <Avatar src={user.profileData?.photoURL} />{' '}
                  <p className="text-body-secondary d-sm-inline-block m-0 avatar-text d-none">
                    {user.profileData?.firstName} {user.profileData?.surName}{' '}
                  </p>{' '}
                </span>
              </Link>
            )}
            {!user && (
              <Link to="/login" className="text-body-secondary nav-avatar d-sm-inline-block">
                <i className="bi bi-person-fill"></i> <p className="text-body-secondary d-none m-0">Not logged in </p>{' '}
              </Link>
            )}
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#main-nav"
            aria-controls="main-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse justify-content-end" id="main-nav">
            <ul className="navbar-nav nav">
              <li className="nav-item d-md-none">
                <NavLink to="/" className="nav-link ({ isActive }) => (isActive ? 'active' : '') ">
                  <span data-bs-toggle="collapse" data-bs-target="#main-nav">
                    <i className="bi bi-house pe-2"></i>
                    Home
                  </span>
                </NavLink>
              </li>
              {user && (
                <>
                  <li className="nav-item d-md-none">
                    <NavLink to={`/profile/${user.uid}`} className="nav-link ({ isActive }) => (isActive ? 'active' : '') ">
                      <span data-bs-toggle="collapse" data-bs-target="#main-nav">
                        <i className="bi bi-person-fill pe-2"></i>
                        Profile
                      </span>
                    </NavLink>
                    <NavLink to="/events" className="nav-link ({ isActive }) => (isActive ? 'active' : '') ">
                      <span data-bs-toggle="collapse" data-bs-target="#main-nav">
                        <i className="bi bi-calendar-date pe-2"></i>
                        Events
                      </span>
                    </NavLink>
                    <NavLink to="/roster" className="nav-link ({ isActive }) => (isActive ? 'active' : '') ">
                      <span data-bs-toggle="collapse" data-bs-target="#main-nav">
                        <i className="bi bi-people-fill pe-2"></i>
                        Roster
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
              {!user && (
                <>
                  <li className="nav-item">
                    <Link to="/signup" className="nav-link btn" href="#">
                      <span className="d-md-none" data-bs-toggle="collapse" data-bs-target="#main-nav">
                        Signup
                      </span>
                      <span className="d-none d-md-block">Signup</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/login" className="nav-link nav-link-c btn btn-secondary text-white" href="#">
                      <span className="d-md-none" data-bs-toggle="collapse" data-bs-target="#main-nav">
                        Login
                      </span>
                      <span className="d-none d-md-block">Login</span>
                    </Link>
                  </li>
                </>
              )}
              {user && (
                <li className="nav-item">
                  {isPending && (
                    <Link to="/" type="button" className="nav-link btn btn-outline-secondary" disabled>
                      Logging out...
                    </Link>
                  )}

                  {!isPending && (
                    <>
                      <span className="d-md-none" data-bs-toggle="collapse" data-bs-target="#main-nav">
                        <Link to="/" onClick={logout} className="nav-link btn btn-secondary text-white mx-3 my-2 my-md-0">
                          Logout
                        </Link>
                      </span>
                      <span className="d-none d-md-block">
                        <Link to="/" onClick={logout} className="nav-link btn btn-secondary text-white mx-3 my-2 my-md-0">
                          Logout
                        </Link>
                      </span>
                    </>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {user && (
        <>
          {' '}
          <div
            className="offcanvas offcanvas-start"
            data-bs-backdrop="false"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                Your Profile
              </h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body profile">
              <div className="container-lg">
                <div className="row justify-content-around mb-4">
                  <div className="col-10 text-center">
                    <img className="profile-image" src={user.profileData?.photoURL || defaultProfile} />
                    <h1>{user.displayName}</h1>
                    <small className="text-body-secondary">uid: {user.uid}</small>
                  </div>
                </div>
                <div className="">
                  <div className="card bg-light p-2 mb-2">
                    <div className="">
                      <span>
                        Name: {user.firstName} &apos;{user.displayName}&apos; {user.surName}{' '}
                        <a
                          className="text-decoration-none float-end text-primary"
                          data-bs-toggle="collapse"
                          href="#nameCollapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls="nameCollapse"
                        >
                          <i className="bi bi-pencil-square"></i> Edit
                        </a>
                      </span>
                      <div className="mb-2 collapse" id="nameCollapse">
                        <div className="card card-body">
                          <form onSubmit={handleSubmit}>
                            <label className="form-label">Change display name:</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={displayName}
                              onChange={(e) => setDisplayName(e.target.value)}
                            />
                            <div className="mt-2 text-center">
                              {isPending && (
                                <button className="btn btn-primary" disabled>
                                  Loading
                                </button>
                              )}
                              {!isPending && (
                                <button className="btn btn-primary" data-bs-toggle="collapse" href="#nameCollapse">
                                  Submit
                                </button>
                              )}
                              {!isPending && (
                                <button type="reset" className="btn btn-outline-danger ms-2" onClick={() => handleCancel('dn')}>
                                  Cancel
                                </button>
                              )}
                              {error && <div className="alert alert-warning">{error}</div>}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card bg-light">
                    <div className="card-header">Login details</div>
                    <div className="card-body">
                      <span>
                        Email: {user.email}{' '}
                        <a
                          className="text-decoration-none float-end"
                          data-bs-toggle="collapse"
                          href="#emailCollapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls="emailCollapse"
                        >
                          <i className="bi bi-pencil-square"></i> Edit
                        </a>
                      </span>
                      <div className="mb-2 collapse" id="emailCollapse">
                        <div className="card card-body">
                          <form onSubmit={handleSubmit}>
                            <label className="form-label">Change email:</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="mt-2 text-center">
                              {isPending && (
                                <button className="btn btn-primary" disabled>
                                  Loading
                                </button>
                              )}
                              {!isPending && <button className="btn btn-primary">Submit</button>}
                              {!isPending && (
                                <button type="reset" className="btn btn-outline-danger ms-2" onClick={() => handleCancel('em')}>
                                  Cancel
                                </button>
                              )}
                              {error && <div className="alert alert-warning">{error}</div>}
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="">
                        <span>
                          Change password:
                          <a
                            className="float-end text-decoration-none"
                            data-bs-toggle="collapse"
                            href="#passwordCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="passwordCollapse"
                          >
                            <i className="bi bi-pencil-square"></i> Edit
                          </a>
                        </span>
                        <div className="collapse" id="passwordCollapse">
                          <div className="card card-body">
                            <form onSubmit={handleSubmit}>
                              <div className="mb-3">
                                <label className="form-label">Old Password:</label>
                                <input type="password" className="form-control" required />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">New Password:</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  required
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Confirm New Password:</label>
                                <input type="password" className="form-control" required />
                              </div>
                              <div className="text-center">
                                {isPending && (
                                  <button className="btn btn-primary" disabled>
                                    Loading
                                  </button>
                                )}
                                {!isPending && <button className="btn btn-primary">Submit</button>}
                                {!isPending && (
                                  <button type="reset" className="btn btn-outline-danger ms-2" onClick={() => handleCancel('pw')}>
                                    Cancel
                                  </button>
                                )}
                                {error && <div className="alert alert-warning">{error}</div>}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mb-3">
                <label className='form-label'>Profile picture:</label>
                <input type="file" className='form-control' required onChange={handleFileChange}/>
                {thumbnailError && <div className='alert alert-warning'>{thumbnailError}</div>}
            </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
    // <div className='navbar' style={{background: color}}>
    //     <nav>
    //         <Link to="/" className='brand'>
    //             <h1>MAKBOX Recipe Book</h1>
    //         </Link>
    //         {user && (
    //         <>
    //          <Link className='btn' to="/create">Create Recipe</Link>
    //         </>
    //         )}
    //         {!user && (
    //         <>
    //         <Link className='btn' to="/signup">Signup</Link>
    //         <Link className='btn' to="/login">Login</Link>
    //         </>
    //         )}
    //         {user && (
    //         <>
    //         {isPending && <Link className='btn' to="/" disabled>Logging out...</Link>}
    //         {!isPending && <Link className='btn' to="/" onClick={logout}>Logout</Link>}
    //         </>
    //         )}
    //     </nav>
    // </div>
  );
}
