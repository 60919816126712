import { React, useEffect, useState } from 'react';
import { projectFirestore } from '../../config/config';
import NewsItem from '../../components/newsItem/newsItem';
import { useFirestoreCollection } from '../../hooks/useFirestoreCollection';
import { useDocument } from '../../hooks/useDocument';

//TODO: Add pagination
//FIXME: fix spamming of multiple event changes.

export default function NewsFeed() {
  document.title = 'OtterBox - Newsfeed';

  // const { data, isPending, error, setLimit } = useFirestoreCollection('newsFeed', null, 10, 'createdAt');
  const { document: aggNewsData, isPending, error } = useDocument('aggregatedData', 'newsFeed');
  const [profiles, setProfiles] = useState({}); // Store profiles here

  // Check if profile data is already cached in localStorage
  useEffect(() => {
    const cachedProfiles = sessionStorage.getItem('cachedProfiles');
    if (cachedProfiles) {
      setProfiles(JSON.parse(cachedProfiles)); // Use cached data
    }
  }, []);

  // Fetch missing profiles and update state only once when the data changes
  useEffect(() => {
    if (!aggNewsData) return;
    const userIds = [...new Set(aggNewsData?.newsItems.map((item) => item.user))]; // Get unique user IDs
    const profilesToFetch = userIds.filter((id) => !profiles[id]); // Filter profiles not in cache

    if (profilesToFetch.length > 0) {
      console.log('Fetching profiles...', profilesToFetch.length);
      // Split profilesToFetch into batches of 10 IDs each
      const batchSize = 10;
      const fetchPromises = [];

      for (let i = 0; i < profilesToFetch.length; i += batchSize) {
        const batch = profilesToFetch.slice(i, i + batchSize);
        const fetchBatch = projectFirestore.collection('users').where('__name__', 'in', batch).get();
        fetchPromises.push(fetchBatch);
      }

      // Execute all batched queries in parallel
      Promise.all(fetchPromises)
        .then((snapshots) => {
          const newProfiles = {};
          snapshots.forEach((snapshot) => {
            snapshot.forEach((doc) => {
              newProfiles[doc.id] = doc.data();
            });
          });

          // Update profiles in the state
          setProfiles((prevProfiles) => {
            const updatedProfiles = { ...prevProfiles, ...newProfiles };

            // Store the updated profiles in localStorage
            sessionStorage.setItem('cachedProfiles', JSON.stringify(updatedProfiles));

            return updatedProfiles;
          });
        })
        .catch((err) => {
          console.log('Error fetching profiles:', err);
        });
    } else {
      console.log('No missing profiles to fetch');
    }
  }, [aggNewsData, profiles]);

  return (
    <div>
      <NewsItem data={aggNewsData?.newsItems} profiles={profiles} />
      <div className="d-flex justify-content-center">
        <button className="btn btn-primary">Load more...</button>
      </div>
      {isPending && <div>Loading...</div>}
      {error && <div>{error}</div>}
    </div>
  );
}
