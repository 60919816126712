import { React, useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useFirestoreCollection } from '../../hooks/useFirestoreCollection';
import Avatar from '../../components/avatar/Avatar';
import { useDocument } from '../../hooks/useDocument';
import { Link } from 'react-router-dom';

//FIXME limit team display
//TODO add search, pagination
export default function Roster() {
  const [filteredRoster, setFilteredRoster] = useState([]);
  // const { data, isPending, error, setLimit } = useFirestoreCollection('users', null, 10, 'createdAt');
  const { document: users, error, isPending } = useDocument('aggregatedData', 'users');

  useEffect(() => {
    if (users) {
      const filteredUsers = users.users.filter((user) => !user.deleted);
      setFilteredRoster(filteredUsers);
    }
  }, [users]);

  return (
    <div className="container">
      <div className="row p-0 m-0 justify-content-left">
        {users &&
          filteredRoster.map((user) => (
            <div key={user.id} className="col-12 col-lg-6 mt-2">
              <div className="">
                <Link to={`/profile/${user.id}`} className="text-decoration-none">
                  <div className=" p-3 card m-0">
                    <div className="d-flex large-avatar">
                      <div className="d-flex">
                        <Avatar src={(user.profileData && user.profileData.photoURL) || null} />
                        <div className="ms-1">
                          <div>
                            {user.profileData.firstName} {user.profileData.alias && <>{`"${user.profileData.alias}"`}</>}{' '}
                            {user.profileData.surName}
                          </div>
                          {user.profileData.teams && <div className="text-muted">{user?.profileData?.teams.join(', ')}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        <div className="d-flex justify-content-center">
          <button className="btn btn-primary mt-3">Load more...</button>
        </div>
        {isPending && <div>Loading...</div>}
        {error && <div>{error}</div>}
      </div>
    </div>
  );
}
