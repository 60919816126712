import React from 'react';
import defaultProfile from '../../assets/defaultProfile.png';

export default function Avatar({ src }) {
  return (
    <div className="avatar">
      <img src={src || defaultProfile} alt="" />
    </div>
  );
}
