// imports
import { BrowserRouter } from 'react-router-dom';
import React from 'react';

// pages
import Home from './pages/home/home';

//components
import Header from './components/header/header';
import { useAuthContext } from './hooks/useAuthContext';
import Sidebar from './components/sidebar/Sidebar';

function App() {
  const { authIsReady, user } = useAuthContext();

  return (
    <div className="App">
      <BrowserRouter>
        <div className="container-fluid p-0">
          {authIsReady && (
            <>
              <Header />
              <div className="row">
                {user && (
                  <>
                    <Sidebar />
                    <div className="col-md-3"></div>
                  </>
                )}
                <Home />
              </div>
            </>
          )}
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
