import { useState, useEffect } from 'react';
import { projectAuth, projectFirestore } from '../config/config';
import { useAuthContext } from './useAuthContext';

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch, user } = useAuthContext();
  const [isCancelled, setIsCancelled] = useState(false);

  const login = async (email, password) => {
    setError(null);
    setIsPending(true);

    try {
      if (!email || !password) {
        throw new Error('Email and password are required');
      }

      const res = await projectAuth.signInWithEmailAndPassword(email, password);
      if (!res || !res.user) {
        throw new Error('Could not login');
      }

      await projectFirestore.collection('users').doc(res.user.uid).update({ online: true });

      dispatch({ type: 'LOGIN', payload: res.user });
      setIsPending(false);
      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        console.log(err.message);
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { login, error, isPending };
};
