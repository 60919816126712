import React from 'react';
import { useDocument } from '../../hooks/useDocument';
import Avatar from '../avatar/Avatar';

export default function PlayerInfo({ player, size }) {
  const { document: user, error } = useDocument('users', player);

  if (error) return <div>Error: {error}</div>;
  if (!user) return <div>Loading user data...</div>;

  return (
    <>
      <div className="d-flex position-relative m-1">
        <Avatar src={user.profileData?.photoURL} className="align-self-center" />
        {size === 'sm' && (
          <span className="position-absolute bottom-0 start-50 translate-middle-x badge rounded-pill bg-danger">
            {user.profileData.firstName.charAt(0)}
            {user.profileData.surName.charAt(0)}
          </span>
        )}
        {!size && (
          <span className="align-self-center ms-3">
            <div>
              {user.profileData.firstName} {user.profileData.surName}
            </div>
          </span>
        )}
      </div>
    </>
  );
}
