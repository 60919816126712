import { React, useState, useRef, useEffect } from 'react';
import { useFirestore } from '../../hooks/useFirestore';
import { useAuthContext } from '../../hooks/useAuthContext';
import { projectFirestore } from '../../config/config';
import EventsList from '../../components/eventsList/eventsList';
import { useNewsFeedPost } from '../../hooks/useNewsFeedPost';
import { PlacesAutocomplete } from '../../components/autocomplete/PlacesAutoComplete';
import { timestamp } from '../../config/config';
import { useDocument } from '../../hooks/useDocument';
import { Modal, Form, InputGroup, Button, ListGroup } from 'react-bootstrap';

export default function Events() {
  const { user, authIsReady } = useAuthContext();
  const [eventName, setEventName] = useState('');
  const [eventLocation, setEventLocation] = useState('');
  const [eventTown, setEventTown] = useState('');
  const [venueName, setVenueName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [eventWebsite, setEventWebsite] = useState('');
  const [ticketSite, setTicketSite] = useState('');
  const [newSubEvents, setNewSubEvents] = useState('');
  const [subEvents, setSubEvents] = useState([]);
  const [team, setTeam] = useState('');
  const [notes, setNotes] = useState('');
  const subEventInput = useRef(null);
  const { newsCreate, newsUpdate, newsDelete } = useNewsFeedPost();
  const [eventFilter, setEventFilter] = useState('');

  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);
  const [savedDate, setSavedDate] = useState(null);
  const [clearInput, setClearInput] = useState(false);
  const [pastEventsToggle, setPastEventsToggle] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);

  document.title = 'OtterBox - Events';
  const dateToday = new Date().toISOString().split('T')[0];

  const { document: events } = useDocument('aggregatedData', 'events');
  useEffect(() => {
    let filteredDeletedEvents = events?.events.filter((event) => event.deleted === false);
    let filteredEvents = filteredDeletedEvents?.filter((event) => {
      const eventName = event.eventName.toLowerCase();
      const search = eventFilter.toLowerCase();
      return eventName.includes(search);
    });
    if (pastEventsToggle) {
      setData(filteredEvents?.filter((event) => new Date(event.endDate) < new Date(Date.now() - 1000 * 60 * 60 * 24 * 1)));
    } else {
      setData(filteredEvents?.filter((event) => new Date(event.endDate) > new Date(Date.now() - 1000 * 60 * 60 * 24 * 1)));
    }
    // setData(events?.events);
  }, [events, pastEventsToggle, eventFilter]);

  const { addDocument, softDeleteDocument, deleteDocument, updateDocument, response } = useFirestore('events');
  useEffect(() => {
    if (response.success) {
      console.log('doc added', response);
    }
    if (response.error) {
      console.log('error', response.error);
    }
  }, [response]);

  const handleAddSubEvent = (e) => {
    e.preventDefault();
    const eventName = newSubEvents.trim();

    // Check if the event name is not empty and doesn't already exist in subEvents
    if (eventName && !subEvents.some((subEvent) => subEvent.name === eventName)) {
      const newSubEvent = {
        name: eventName, // Use eventName as the sub-event name
        players: [], // Initialize with an empty players array
      };

      // Add the new sub-event object to the list
      setSubEvents((prevSubEvents) => [...prevSubEvents, newSubEvent]);
    }

    // Reset the input and focus
    setNewSubEvents(''); // Clear the input field
    subEventInput.current.focus(); // Set focus back to the input
  };

  const handleRemoveSubEvent = (e) => {
    const str = e.target.innerHTML.trim(); // Get the event name from the element and trim any extra spaces

    // Find the index of the sub-event object where the name matches the string
    const index = subEvents.findIndex((subEvent) => subEvent.name === str);

    if (index > -1) {
      // Create a new array excluding the sub-event with the matched name
      const updatedSubEvents = [...subEvents];
      updatedSubEvents.splice(index, 1); // Remove the sub-event at the found index

      // Update the state with the new array
      setSubEvents(updatedSubEvents);
    }
  };

  const handleSubmitNewEvent = async (e) => {
    e.preventDefault();
    if (!eventName || !eventLocation || !startDate || !endDate) {
      alert('Please enter all required information');
      setError('eventName, eventLocation, startDate, and endDate are all required information');
      setIsPending(false);
      return;
    }
    const eventWebsiteTrimmed = eventWebsite ? eventWebsite.replace(/(https?:\/\/)/, '') : '';
    const ticketSiteTrimmed = ticketSite ? ticketSite.replace(/(https?:\/\/)/, '') : '';
    try {
      const addedDocumentRef = await addDocument({
        eventName,
        eventLocation,
        eventTown,
        venueName,
        startDate,
        endDate,
        eventWebsite: eventWebsiteTrimmed,
        ticketSite: ticketSiteTrimmed,
        subEvents: [...subEvents],
        players: { going: [], interested: [] },
        user: user.uid,
        deleted: false,
        team,
        notes,
      });

      if (addedDocumentRef) {
        // console.log('Document added successfully with ID:', addedDocumentRef.id);
        newsCreate({
          user: user.uid,
          category: 'event',
          type: 'create',
          categoryName: eventName,
          entryId: addedDocumentRef.id,
        });
        setShowCreateEventModal(false);
      } else {
        console.error('Error: addedDocumentRef is undefined');
      }
    } catch (error) {
      console.error('Error adding document or creating news item:', error);
    }
  };

  const clearForm = () => {
    setEventName('');
    setEventLocation('');
    setStartDate('');
    setEndDate('');
    setEventWebsite('');
    setTicketSite('');
    setSubEvents([]);
    setTeam('');
    setNotes('');
    setClearInput(true);
    setShowCreateEventModal(false);
  };

  const clearFilter = (e) => {
    e.preventDefault();
    setEventFilter('');
  };

  return (
    <>
      <div className="card p-5">
        <h1>Events</h1>
        <div className="row">
          <div className="col-xs-6 col-md-9">
            <div className="input-group mb-3">
              <input
                type="text"
                id="eventFilter"
                className="form-control"
                placeholder="Search for event..."
                onChange={(e) => setEventFilter(e.target.value)}
                value={eventFilter}
              />
              <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={clearFilter}>
                Clear
              </button>
            </div>
          </div>
          <div className="col-xs-6 col-md-3">
            <button className="btn btn-primary w-100" onClick={() => setShowCreateEventModal(true)}>
              Create event
            </button>
          </div>
        </div>
        <div className="row"></div>
        <button className="btn btn-link align-self-start" onClick={() => setPastEventsToggle(!pastEventsToggle)}>
          {pastEventsToggle ? 'Show future events' : 'Show past events'}
        </button>
        {data && <EventsList events={data} />}
      </div>

      <Modal show={showCreateEventModal} backdrop="static" onHide={clearForm} id="createEventModal">
        <Modal.Header closeButton>
          <Modal.Title>Create Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="eventName">
              <Form.Label>Event Name</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Event Name*"
                onChange={(e) => setEventName(e.target.value)}
                value={eventName}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Event Location</Form.Label>
              <PlacesAutocomplete
                setEventLocation={setEventLocation}
                setEventTown={setEventTown}
                setVenueName={setVenueName}
                clearInput={clearInput}
                setClearInput={setClearInput}
                venueName={venueName}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                required
                placeholder="Event Start Date*"
                onChange={(e) => {
                  const newStartDate = e.target.value;
                  const newEndDate = endDate ? (endDate < newStartDate ? newStartDate : endDate) : newStartDate;
                  setStartDate(newStartDate);
                  setEndDate(newEndDate);
                }}
                value={startDate}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                required
                placeholder="Event End Date*"
                min={startDate}
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="team">
              <Form.Label>Team</Form.Label>
              <Form.Control type="text" placeholder="Team" onChange={(e) => setTeam(e.target.value)} value={team} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="eventWebsite">
              <Form.Label>Event Website</Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon3">https://</InputGroup.Text>
                <Form.Control
                  type="url"
                  placeholder="Event Website"
                  onChange={(e) => setEventWebsite(e.target.value)}
                  value={eventWebsite}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="ticketSite">
              <Form.Label>Tickets Site</Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon3">https://</InputGroup.Text>
                <Form.Control
                  type="url"
                  placeholder="Tickets Site"
                  onChange={(e) => setTicketSite(e.target.value)}
                  value={ticketSite}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Sub event</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Sub event"
                  onChange={(e) => setNewSubEvents(e.target.value)}
                  value={newSubEvents}
                  ref={subEventInput}
                />
                <Button onClick={handleAddSubEvent} variant="outline-secondary" id="button-addon2">
                  Add
                </Button>
              </InputGroup>
            </Form.Group>
            <p>Current sub-events (click to remove):</p>
            <ListGroup className="mb-3">
              {subEvents.map((i) => {
                return (
                  <ListGroup.Item key={i.name} onClick={(e) => handleRemoveSubEvent(e)} name={i.name} action>
                    {i.name}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
            <Form.Group className="mb-3" controlId="notes">
              <Form.Label>Notes</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Notes" onChange={(e) => setNotes(e.target.value)} value={notes} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={clearForm}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmitNewEvent}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
