import { useState, useEffect } from 'react';
import { projectAuth, projectFirestore, timestamp } from '../config/config';
import { useAuthContext } from './useAuthContext';

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();
  const [isCancelled, setIsCancelled] = useState(false);

  const signup = async (email, password, alias, firstName, surName) => {
    setError(null);
    setIsPending(true);

    try {
      const res = await projectAuth.createUserWithEmailAndPassword(email, password);

      if (!res) {
        throw new Error('could not complete signup');
      }

      //upload thumbnail
      // const uploadPath = `profileThumbs/${res.user.uid}/${thumbnail.name}`;
      // const img = await projectStorage.ref(uploadPath).put(thumbnail);

      // const photoURL = await img.ref.getDownloadURL();

      await res.user.updateProfile({ displayName: alias });
      const createdAt = timestamp.fromDate(new Date());
      // create user document
      await projectFirestore.collection('users').doc(res.user.uid).set({
        deleted: false,
        createdAt,
        profileData: {
          firstName,
          surName,
          alias,
        },
      });

      //log new user in
      dispatch({ type: 'LOGIN', payload: res.user });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        console.log(err.message);
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { error, isPending, signup };
};
