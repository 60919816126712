import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBsNbt9ZHoaCz4eYgTUkrFj_tMVEAYytjg',
  authDomain: 'otterbox-3abf3.firebaseapp.com',
  projectId: 'otterbox-3abf3',
  storageBucket: 'otterbox-3abf3.appspot.com',
  messagingSenderId: '782294859593',
  appId: '1:782294859593:web:8ca5bab45ce9baba26a498',
  measurementId: 'G-859CGJ657Z',
};

firebase.initializeApp(firebaseConfig);

const projectFirestore = firebase.firestore();
if (document.location.hostname === 'localhost') {
  projectFirestore.useEmulator('127.0.0.1', 8080);
}
const projectAuth = firebase.auth();
if (document.location.hostname === 'localhost') {
  projectAuth.useEmulator('http://127.0.0.1:9099');
}
const projectStorage = firebase.storage();
const analytics = firebase.analytics();

const timestamp = firebase.firestore.Timestamp;

// firebase.firestore().clearPersistence();
// firebase.firestore().enablePersistence();

export { projectFirestore, analytics, projectAuth, projectStorage, timestamp };
