import { useAuthContext } from './useAuthContext';
import { useState } from 'react';
import { projectFirestore, projectStorage, timestamp } from '../config/config';
import firebase from 'firebase/app'; // Important for Firebase 8.x
import FileResizer from 'react-image-file-resizer';
import getCroppedImg from '../utils/getCroppedImage';
import { useNavigate } from 'react-router';

export const useUpdateProfile = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch, plainUser: user } = useAuthContext();

  // Crop state management
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const navigate = useNavigate();
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  // Function to crop the image
  const cropImage = async (imageFile) => {
    try {
      const croppedImage = await getCroppedImg(imageFile, croppedAreaPixels);
      return croppedImage;
    } catch (error) {
      setError('Error cropping image');
      console.error(error);
    }
  };

  // Resize Image
  const resizeImage = (imageFile, callback) => {
    FileResizer.imageFileResizer(
      imageFile,
      300, // width
      300, // height
      'JPEG',
      100, // quality
      0, // rotation
      (uri) => {
        callback(uri);
      },
      'blob', // Output format: blob
    );
  };

  // Update user profile
  const updateProfile = async (updates) => {
    setError(null);
    setIsPending(true);

    try {
      // Update profile in auth
      await user.updateProfile({
        displayName: updates?.alias,
      });

      // Update Firestore user document
      const userRef = projectFirestore.collection('users').doc(user.uid);
      const userDoc = await userRef.get();
      const oldProfileData = userDoc.data().profileData;
      const newProfileData = { ...oldProfileData, ...updates.update };

      await userRef.update({
        profileData: newProfileData,
      });

      setIsPending(false);
    } catch (err) {
      setError(err.message);
      setIsPending(false);
    }
  };

  const createProfile = async (updates) => {
    setError(null);
    setIsPending(true);
    let firstName = updates?.update?.firstName;
    let surName = updates?.update?.surName;
    let alias = updates?.update?.alias;
    const createdAt = timestamp.fromDate(new Date());

    try {
      // Update the user's Firestore profile data
      await projectFirestore
        .collection('users')
        .doc(user.uid)
        .set({
          deleted: false,
          createdAt,
          profileData: {
            firstName,
            surName,
            alias: alias || null,
          },
        });

      // Once profile is created, dispatch the profileData to the AuthContext
      dispatch({
        type: 'SET_USER_DATA',
        payload: { profileData: updates.update },
      });

      navigate('/profile');

      setIsPending(false);
    } catch (error) {
      console.log(error);
      setError(error.message);
      setIsPending(false);
    }
  };

  const deleteGoogleUser = async () => {
    setError(null);
    setIsPending(true);
    try {
      const updateProfile = {
        deleted: true,
        profileData: {
          alias: 'Deleted User',
          firstName: 'Deleted',
          surName: 'User',
          photoURL:
            'https://firebasestorage.googleapis.com/v0/b/otterbox-3abf3.appspot.com/o/deletedProfile.jpg?alt=media&token=a3314d10-f68d-4f21-b10f-feb455de5a6b',
        },
      };

      const userRef = projectFirestore.collection('users').doc(user.uid);
      await userRef.update({
        ...updateProfile,
      });
      await user.delete();
      setIsPending(false);
      return { success: true };
    } catch (err) {
      setError(err.message);
      setIsPending(false);
      return { success: false, error: err.message };
    }
  };

  const deleteAccount = async (password) => {
    setError(null);
    setIsPending(true);
    const updateProfile = {
      deleted: true,
      profileData: {
        alias: 'Deleted User',
        firstName: 'Deleted',
        surName: 'User',
        photoURL:
          'https://firebasestorage.googleapis.com/v0/b/otterbox-3abf3.appspot.com/o/deletedProfile.jpg?alt=media&token=a3314d10-f68d-4f21-b10f-feb455de5a6b',
      },
    };
    const userRef = projectFirestore.collection('users').doc(user.uid);

    try {
      // Reauthenticate the user
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);
      await user.reauthenticateWithCredential(credential);
      await user.updateEmail(`deleted-${Math.random()}@makbox.com`);
      await user.updateProfile({ displayName: 'Deleted User' });
      // await user.updateProfilePicture(updateProfile.profileData.photoURL);
      await userRef.update({
        ...updateProfile,
      });
      setIsPending(false);
      return { success: true };
    } catch (err) {
      setError(err.message);
      setIsPending(false);
      return { success: false, error: err.message };
    }
  };
  // Update user email
  const updateEmail = async (newEmail, password) => {
    setError(null);
    setIsPending(true);

    try {
      // Reauthenticate the user
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);
      await user.reauthenticateWithCredential(credential);

      // Update the email
      await user.updateEmail(newEmail);

      setIsPending(false);
    } catch (err) {
      setError(err.message);
      setIsPending(false);
    }
  };

  // Update user profile picture
  const updateProfilePicture = async (file) => {
    setError(null);

    try {
      const croppedImage = await cropImage(file);

      resizeImage(croppedImage, async (resizedImage) => {
        const storageRef = projectStorage.ref(`users/${user.uid}/profilePicture`);
        const userRef = projectFirestore.collection('users').doc(user.uid);

        const uploadTask = storageRef.put(resizedImage);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Optionally, handle upload progress
          },
          (error) => {
            console.error('Upload error:', error);
            setError('Failed to upload the image');
          },
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();

            // Update profile picture in Firebase Authentication
            await user.updateProfile({
              photoURL: downloadURL,
            });

            // Fetch the old profile data from Firestore
            const userDoc = await userRef.get();
            const oldProfileData = userDoc.data().profileData;

            // Update the profileData in Firestore with the new photoURL
            const newProfileData = { ...oldProfileData, photoURL: downloadURL };
            await userRef.update({
              profileData: newProfileData,
            });

            console.log('Profile picture updated successfully');
          },
        );
      });
    } catch (error) {
      setError('Error processing profile picture');
      console.error(error);
    }
  };

  return {
    crop,
    setCrop,
    zoom,
    setZoom,
    onCropComplete,
    createProfile,
    updateProfilePicture,
    updateProfile,
    updateEmail,
    error,
    isPending,
    deleteAccount,
    deleteGoogleUser,
  };
};
