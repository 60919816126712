// import RecipeList from '../../components/RecipeList'
import { React, projectFirestore } from '../../config/config';
import Profile from '../profile/profile';
import NewsFeed from '../newsFeed/newsFeed';
import { Routes, Route } from 'react-router';
import { useEffect } from 'react';
import { useState } from 'react';
import Events from '../events/events';
import { useAuthContext } from '../../hooks/useAuthContext';
import Login from '../login/Login';
import Signup from '../signup/Signup';
import EventDetails from '../eventDetails/eventDetails';
import Roster from '../roster/roster';

export default function Home() {
  const { user, authIsReady } = useAuthContext();
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);
  const [savedDate, setSavedDate] = useState('');

  document.title = 'OtterBox';
  if (!user) {
    return (
      <div className="col mt-5 pt-4">
        <Routes>
          <Route path="/signup" element={<Signup />} />
          {/* <Route path="/newsfeed" element={<NewsFeed />} /> */}
          {/* <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<EventDetails />} /> */}
          <Route path="/*" element={<Login />} />
        </Routes>
      </div>
    );
  }
  if (!user.profileData) {
    return (
      <div className="container-offset col-sm-10 offset-sm-1 offset-md-1 col-md-7 offset-lg-0 col-lg-8 align-self-center p-0 card-clear">
        <Routes>
          <Route path="/*" element={<Profile />} />
        </Routes>
      </div>
    );
  }
  return (
    <>
      <div className="container-offset-sm col-sm-10 offset-sm-1 offset-md-1 col-md-7 offset-lg-0 col-lg-8 align-self-center p-0 card-clear">
        <Routes>
          <Route path="/" element={<NewsFeed />} />
          <Route path="/login" element={<NewsFeed />} />
          <Route path="/roster" element={<Roster />} />
          <Route path="/roster/:id" element={<Profile />} />
          <Route path="/signup" element={<NewsFeed />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<EventDetails />} />
          <Route path="/*" element={<NewsFeed />} />
          {/* <Route path="/settings" element={<Settings />} /> */}
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </>
  );
}
