import React from 'react';
import ReactDOM from 'react-dom/client'; // updated for React 18
import 'bootstrap';
import './css/index.min.css';
import './css/custom.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { AdminContextProvider } from './context/AdminContext';

// Get the root element
const rootElement = document.getElementById('root');

// Create a root for React 18
const root = ReactDOM.createRoot(rootElement);

// Render the app
root.render(
  // <React.StrictMode>
  <AuthContextProvider>
    <AdminContextProvider>
      <App />
    </AdminContextProvider>
  </AuthContextProvider>,
  // </React.StrictMode>,
);
