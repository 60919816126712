import { useParams, useNavigate, Link } from 'react-router-dom';
import { useEffect, useState, React, useRef } from 'react';
import { projectFirestore } from '../../config/config';
import { useFirestore } from '../../hooks/useFirestore';
import { useAuthContext } from '../../hooks/useAuthContext';
import Avatar from '../../components/avatar/Avatar';
import { Dropdown, DropdownButton, Button, Modal } from 'react-bootstrap';
import { useNewsFeedPost } from '../../hooks/useNewsFeedPost';
import { useDocument } from '../../hooks/useDocument';
import { PlacesAutocomplete } from '../../components/autocomplete/PlacesAutoComplete';
import { EventDiscussion } from '../../components/eventDiscussion/EventDiscussion';
import PlayerInfo from '../../components/playerInfo/PlayerInfo';
import Select from 'react-select';
import defaultProfile from '../../assets/defaultProfile.png';

//TODO make conversations work
//TODO add logistics overview
//FIXME buttons are uneven on mobile

export default function EventDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, authIsReady } = useAuthContext();
  const { softDeleteDocument, updateDocument, response } = useFirestore('events');
  const { document: users } = useDocument('aggregatedData', 'users');
  // FIXME remove deleted users from aggregatedData
  const { newsCreate } = useNewsFeedPost();

  const [event, setEvent] = useState(null);
  const [eventCreator, setEventCreator] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);

  const [eventName, setEventName] = useState('');
  const [venueName, setVenueName] = useState('');
  const [eventLocation, setEventLocation] = useState('');
  const [eventTown, setEventTown] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [eventWebsite, setEventWebsite] = useState('');
  const [ticketSite, setTicketSite] = useState('');
  const [newSubEvents, setNewSubEvents] = useState('');
  const [subEvents, setSubEvents] = useState([]);
  const [team, setTeam] = useState('');
  const [notes, setNotes] = useState('');
  const subEventInput = useRef(null);
  const [comment, setComment] = useState('');
  const [arrival, setArrival] = useState(null);
  const [sleeping, setSleeping] = useState(null);
  const [transport, setTransport] = useState(null);
  const [showLogisticsModal, setShowLogisticsModal] = useState(false);
  const [clearInput, setClearInput] = useState(false);

  document.title = `OtterBox - ${event?.eventName}`;
  const handleAddSubEvent = (e) => {
    e.preventDefault();
    const eventName = newSubEvents.trim();

    // Check if the event name is not empty and doesn't already exist in subEvents
    if (eventName && !subEvents.some((subEvent) => subEvent.name === eventName)) {
      const newSubEvent = {
        name: eventName, // Use eventName as the sub-event name
        players: [], // Initialize with an empty players array
      };

      // Add the new sub-event object to the list
      setSubEvents((prevSubEvents) => [...prevSubEvents, newSubEvent]);
    }

    // Reset the input and focus
    setNewSubEvents(''); // Clear the input field
    subEventInput.current.focus(); // Set focus back to the input
  };

  const getDayOptions = () => {
    const startDateObj = new Date(event?.startDate);
    const endDateObj = new Date(event?.endDate);
    const timeDifference = endDateObj.getTime() - startDateObj.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const days = [];
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    for (let i = 0; i <= daysDifference; i++) {
      const date = new Date(startDateObj.getTime() + i * 1000 * 60 * 60 * 24);
      days.push({ value: dayNames[date.getDay()], label: dayNames[date.getDay()] });
    }
    return days;
  };

  const handleSubmitAttendanceData = async (e) => {
    e.preventDefault();
    setShowLogisticsModal(false);

    // Create the attendance data object
    const attendanceData = {
      arrival,
      sleeping,
      transport,
    };

    // Find the user in the 'going' array and update their attendance data
    const updatedGoing = event.players.going.map((player) => {
      console.log(attendanceData, event, user.uid, player.id);
      if (player.id === user.uid) {
        return { ...player, ...attendanceData }; // Update the current user's attendance data
      }
      return player; // Return other players unchanged
    });

    // Update the event object
    const updatedEvent = {
      ...event,
      players: { ...event.players, going: updatedGoing }, // Replace the old 'going' array with the updated one
    };

    // Update the document in the database
    await updateDocument(id, updatedEvent);

    // Clear the form fields
  };

  const handleRemoveSubEvent = (e) => {
    const str = e.target.innerHTML.trim(); // Get the event name from the element and trim any extra spaces

    // Find the index of the sub-event object where the name matches the string
    const index = subEvents.findIndex((subEvent) => subEvent.name === str);

    if (index > -1) {
      // Create a new array excluding the sub-event with the matched name
      const updatedSubEvents = [...subEvents];
      updatedSubEvents.splice(index, 1); // Remove the sub-event at the found index

      // Update the state with the new array
      setSubEvents(updatedSubEvents);
    }
  };

  const clearForm = (e) => {
    e.preventDefault();
    setEventName('');
    setEventLocation('');
    setStartDate('');
    setEndDate('');
    setEventWebsite('');
    setTicketSite('');
    setSubEvents([]);
    setTeam('');
    setNotes('');
    setClearInput(true);
  };

  const handleEditEvent = async (e) => {
    e.preventDefault();
    const data = {
      eventName,
      eventLocation,
      eventTown,
      venueName,
      startDate,
      endDate,
      eventWebsite,
      ticketSite,
      subEvents,
      team,
      notes,
    };
    try {
      const updatedDocument = await updateDocument(id, data);
      console.log('Updated document:', updatedDocument);
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  useEffect(() => {
    setIsPending(true);

    const unsub = projectFirestore
      .collection('events')
      .doc(id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setIsPending(false);
          setEvent(doc.data());
          document.title = doc.eventName;
        } else {
          setIsPending(false);
          setError('Could not find that event');
        }
      });

    return () => unsub();
  }, [id]);

  useEffect(() => {
    if (event !== null) {
      document.title = event.eventName;
    }
    return () => {};
  }, [event]);

  useEffect(() => {
    setIsPending(true);

    if (event !== null) {
      const unsub = projectFirestore
        .collection('users')
        .doc(event.user)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setIsPending(false);
            setEventCreator(doc.data());
          } else {
            setIsPending(false);
            setError('Could not find creator');
          }
        });

      return () => unsub();
    }
  }, [event]);

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you wish to delete this event?')) {
      softDeleteDocument(id);
      navigate('/events');
    }
  };

  useEffect(() => {
    if (event && user) {
      const going = event.players.going.find((player) => player.id === user.uid);
      console.log(going);
      if (going) {
        setArrival(going.arrival);
        setSleeping(going.sleeping);
        setTransport(going.transport);
      }
    }
  }, [event, user]);

  const handleEdit = () => {
    setEventName(event.eventName);
    setEventLocation(event.eventLocation);
    setEventTown(event.eventTown);
    setVenueName(event.venueName);
    setStartDate(event.startDate);
    setEndDate(event.endDate);
    setEventWebsite(event.eventWebsite);
    setTicketSite(event.ticketSite);
    setSubEvents(event.subEvents || []); // copy event.subEvents);
    setTeam(event.team);
    setNotes(event.notes);
  };

  const handleRegisterSubEvent = (id, subevent) => {
    const players = [...subevent.players];
    players.push(user.uid);

    const updatedSubEvent = { ...subevent, players };
    const updatedSubEvents = event.subEvents.map((s) => (s.name === subevent.name ? updatedSubEvent : s));

    const update = { subEvents: updatedSubEvents };
    updateDocument(id, update);
  };
  const handleDeRegisterSubEvent = (id, subevent) => {
    const updatedPlayers = subevent.players.filter((playerId) => playerId !== user.uid);
    const updatedSubEvent = { ...subevent, players: updatedPlayers };
    const updatedSubEvents = event.subEvents.map((s) => (s.name === subevent.name ? updatedSubEvent : s));
    const update = { subEvents: updatedSubEvents };
    updateDocument(id, update);
  };
  const handleInterested = (id) => {
    const players = event.players;
    if (players.going.map((player) => player.id).includes(user.uid)) {
      handleDeRegister(id);
    }
    players.interested.push(user.uid);
    const update = { players };
    updateDocument(id, update);

    newsCreate({
      user: user.uid,
      category: 'eventInterested',
      categoryName: event.eventName,
      entryId: id,
    });
  };

  const handleGoing = (id) => {
    const players = event.players;
    if (players.interested.includes(user.uid)) {
      handleDeRegister(id);
    }
    players.going.push({ id: user.uid, arrival: null, sleeping: null, transport: null });
    const update = { players };
    updateDocument(id, update);

    newsCreate({
      user: user.uid,
      category: 'eventGoing',
      categoryName: event.eventName,
      entryId: id,
    });
  };

  const handleDeRegister = (id) => {
    const players = event.players;
    const uid = players.interested.indexOf(user.uid);

    // Find the index in the 'going' array by checking the 'id' field
    const uid2 = players.going.findIndex((player) => player.id === user.uid);

    // Remove from 'interested' if the user exists
    if (uid > -1) {
      players.interested.splice(uid, 1);
    }

    // Remove from 'going' if the user exists
    if (uid2 > -1) {
      players.going.splice(uid2, 1);
    }

    // Create the update object and update the document
    const update = { players };
    updateDocument(id, update);
  };

  const handleToggleShowLogisicsModal = () => {
    setShowLogisticsModal(!showLogisticsModal);
  };

  return (
    <>
      <div className="card">
        {isPending && (
          <div className="alert alert-info">
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
          </div>
        )}
        {event?.deleted && <div className="alert alert-danger">This event has been deleted.</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        {event && (
          <>
            <div className="container-lg ">
              <div className="row align-items-center g-5">
                <div className="col">
                  <button className="btn" onClick={() => navigate('/events')}>
                    &lt; Back to Events
                  </button>
                  <div className="bg-white bg-opacity-50 p-2 mb-2 rounded">
                    <h1>{event.eventName}</h1>
                    <div className="d-flex">
                      <span className="text-danger fw-bold">
                        {event.startDate.split('-').reverse().join('/')} to {event.endDate.split('-').reverse().join('/')}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {event.eventWebsite && (
                      <a className="btn btn-primary me-1" href={`http://${event.eventWebsite}`} target="_blank" rel="noreferrer">
                        <i className="bi bi-laptop me-2"></i> Website
                      </a>
                    )}
                    {event.ticketSite && (
                      <a className="btn btn-primary me-1" href={`http://${event.ticketSite}`} target="_blank" rel="noreferrer">
                        <i className="bi bi-ticket-perforated me-2"></i> Get Tickets
                      </a>
                    )}
                    <i className="me-auto"></i>
                    {user &&
                      !event.players?.interested?.includes(user?.uid) &&
                      !event.players?.going?.map((player) => player.id).includes(user?.uid) && (
                        <>
                          <button className="btn btn-secondary ms-1 d-none d-md-inline" onClick={() => handleInterested(id)}>
                            <i className="bi bi-star me-1"></i> Interested
                          </button>
                          <button className="btn btn-secondary ms-1 d-none d-md-inline" onClick={() => handleGoing(id)}>
                            <i className="bi bi-check-circle me-1"></i> Going
                          </button>
                          <DropdownButton
                            id="attendanceToggle"
                            className="d-md-none ms-1"
                            variant="secondary"
                            title={
                              <span>
                                <i className="bi bi-x-circle me-1"></i> Not Going
                              </span>
                            }
                          >
                            <Dropdown.Item onClick={() => handleInterested(id)}>
                              <i className="bi bi-star-fill me-1"></i>Interested
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleGoing(id)}>
                              <i className="bi bi-check-circle me-1"></i> Going
                            </Dropdown.Item>
                            <Dropdown.Item className="text-bg-primary">
                              <i className="bi bi-x-circle me-1"></i>Not Going
                            </Dropdown.Item>
                          </DropdownButton>
                        </>
                      )}
                    {event.players?.interested?.includes(user?.uid) && (
                      <>
                        <DropdownButton
                          id="attendanceToggle"
                          title={
                            <span>
                              <i className="bi bi-star-fill me-1"></i> Interested
                            </span>
                          }
                        >
                          <Dropdown.Item className="text-bg-primary">
                            <i className="bi bi-star-fill me-1"></i>Interested
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleGoing(id)}>
                            <i className="bi bi-check-circle me-1"></i> Going
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeRegister(id)}>
                            <i className="bi bi-x-circle me-1"></i>Not Going
                          </Dropdown.Item>
                        </DropdownButton>
                      </>
                    )}
                    {event.players?.going?.map((player) => player.id).includes(user?.uid) && (
                      <>
                        <DropdownButton
                          id="attendanceToggle"
                          title={
                            <span>
                              <i className="bi bi-check-circle me-1"></i> Going
                            </span>
                          }
                        >
                          <Dropdown.Item onClick={() => handleInterested(id)}>
                            <i className="bi bi-star-fill me-1"></i>Interested
                          </Dropdown.Item>
                          <Dropdown.Item className="text-bg-primary">
                            <i className="bi bi-check-circle me-1"></i> Going
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeRegister(id)}>
                            <i className="bi bi-x-circle me-1"></i>Not Going
                          </Dropdown.Item>
                        </DropdownButton>
                      </>
                    )}
                    {user && user.uid === event.user && (
                      <>
                        <DropdownButton align="end" className="ms-1" variant={'secondary'} id="eventAdminToggle" title=". . .">
                          <Dropdown.Item data-bs-toggle="modal" data-bs-target="#editEventModal" onClick={() => handleEdit()}>
                            <i className="bi bi-pencil-square me-1"></i>Edit Event
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDelete(id)}>
                            <i className="bi bi-file-earmark-x me-1"></i> Delete Event
                          </Dropdown.Item>
                        </DropdownButton>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="container-lg">
              <div className="row p-1 justify-content-around ">
                <div className="col-md-12 col-lg-6  m-1 card text-dark bg-light p-3">
                  <h3>Details</h3>
                  <p>
                    <i className="bi bi-people me-2"></i>
                    {event.players?.interested?.length + event.players?.going?.length} responses
                  </p>
                  <p>
                    <i className="bi bi-laptop me-2"></i>
                    <a href={`http://${event.eventWebsite}`}>{event.eventWebsite}</a>
                  </p>
                  <p>
                    <i className="bi bi-ticket-perforated me-2"></i>
                    <a href={`https://${event.ticketSite}`}>{event.ticketSite}</a>
                  </p>
                  <p>
                    <i className="bi bi-geo-alt me-2"></i>
                    {event.eventLocation}
                  </p>
                  {event.subEvents && (
                    <p>
                      <i className="bi bi-list-nested me-2"></i>
                      {event.subEvents.length} sub events
                    </p>
                  )}
                  <p>
                    <i className="bi bi-square-half me-2" alt="Team"></i>
                    {event.team}
                  </p>
                  {event.notes && (
                    <p>
                      <i className="bi bi-card-text me-2"></i>
                      {event.notes}
                    </p>
                  )}
                </div>
                <div className="col m-1 p-0 card">
                  <iframe
                    src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
                      event.eventLocation,
                    )}&key=AIzaSyBijEr_Rrirj6rdNnDO_hkavhc-VvbTKP8`}
                    width="100%"
                    height="100%"
                    style={{ border: '0' }}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="container-lg">
              <div className="row p-1 justify-content-around">
                <div className="col m-1 card text-dark bg-light p-3 d-flex">
                  <h3>Attendance</h3>
                  <div className="row">
                    <div className="d-flex justify-content-around">
                      <span className="text-center">
                        <h2>
                          {event.players?.going?.length} <br />
                          going
                        </h2>
                      </span>
                      <span className="text-center">
                        <h2>
                          {event.players?.interested?.length} <br /> interested
                        </h2>
                      </span>
                    </div>{' '}
                  </div>
                  <div className="row">
                    <div className="container">
                      <div className="card p-2">
                        {event.players?.going?.length > 0 && (
                          <div className="table">
                            <table className="table table-bordered ">
                              {event.startDate !== event.endDate && (
                                <thead className="text-center">
                                  <tr>
                                    <th className="">
                                      <span className="material-icons me-1">person</span>Player
                                    </th>
                                    <th className="d-none d-md-table-cell">
                                      <span className="material-icons me-1">schedule</span>Arriving
                                    </th>
                                    <th className="d-none d-md-table-cell">
                                      <span className="material-icons me-1">hotel</span>Sleeping
                                    </th>
                                    <th className="d-none d-md-table-cell">
                                      <span className="material-icons me-1">commute</span>Travel
                                    </th>
                                    <th className=" d-md-none">Logistics</th>
                                  </tr>
                                </thead>
                              )}
                              <tbody>
                                {event.players?.going?.map((player) => {
                                  const playerData = users.users.find((u) => u.id === player.id);
                                  return (
                                    <tr key={player.id}>
                                      <td>
                                        <div className="d-flex align-content-center">
                                          <Avatar src={playerData?.profileData?.photoURL || defaultProfile} />
                                          <span className="ms-2 align-self-center">
                                            {playerData?.profileData?.firstName} {playerData?.profileData?.surName}{' '}
                                          </span>
                                          {user.uid === player.id && (
                                            <i
                                              className="bi bi-pencil-fill text-primary ms-2 align-self-center d-md-none"
                                              onClick={() => handleToggleShowLogisicsModal()}
                                            ></i>
                                          )}
                                        </div>
                                      </td>
                                      {user.uid === player.id && event.startDate !== event.endDate && (
                                        <>
                                          <td className="d-md-none">
                                            <div>
                                              <span className="material-icons me-1">schedule</span>
                                              {player.arrival}
                                            </div>
                                            <div>
                                              <span className="material-icons me-1">hotel</span>
                                              {player.sleeping}
                                            </div>
                                            <div>
                                              <span className="material-icons me-1">commute</span>
                                              {player.transport}
                                            </div>
                                          </td>
                                          <td className="d-md-table-cell d-none">
                                            <Select
                                              options={getDayOptions()}
                                              value={{ value: arrival, label: arrival }}
                                              placeholder="Arrival"
                                              onChange={(e) => setArrival(e.value)}
                                            />{' '}
                                          </td>
                                          <td className="d-md-table-cell d-none">
                                            <Select
                                              onChange={(e) => setSleeping(e.value)}
                                              value={{ value: sleeping, label: sleeping }}
                                              options={[
                                                { value: 'Tent', label: 'Tent' },
                                                { value: 'Vehicle', label: 'Vehicle' },
                                              ]}
                                              placeholder="Sleeping"
                                            />
                                          </td>
                                          <td className="d-md-table-cell d-none">
                                            <Select
                                              onChange={(e) => setTransport(e.value)}
                                              value={{ value: transport, label: transport }}
                                              options={[
                                                { value: 'Yes', label: 'Yes' },
                                                { value: 'No', label: 'No' },
                                              ]}
                                              placeholder="Transport"
                                            />
                                          </td>
                                          <td className="d-md-table-cell d-none">
                                            <button
                                              className="btn btn-primary btn"
                                              onClick={(e) => handleSubmitAttendanceData(e)}
                                            >
                                              Save
                                            </button>
                                          </td>
                                        </>
                                      )}
                                      {user.uid !== player.id && event.startDate !== event.endDate && (
                                        <>
                                          <td className="d-md-none">
                                            <div>
                                              <span className="material-icons me-1">schedule</span>
                                              {player.arrival}
                                            </div>
                                            <div>
                                              <span className="material-icons me-1">hotel</span>
                                              {player.sleeping}
                                            </div>
                                            <div>
                                              <span className="material-icons me-1">commute</span>
                                              {player.transport}
                                            </div>
                                          </td>
                                          <td className="d-md-table-cell d-none">{player.arrival}</td>
                                          <td className="d-md-table-cell d-none">{player.sleeping}</td>
                                          <td className="d-md-table-cell d-none">{player.transport}</td>
                                        </>
                                      )}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {event.players?.interested?.length > 0 && event.players?.going?.length > 0 && <hr />}
                        {event.players?.interested?.map((playerId) => (
                          <PlayerInfo player={playerId} key={playerId} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {event.subEvents.length > 0 && (
              <section id="subEvents">
                <div className="container-lg">
                  <div className="row p-1 justify-content-around">
                    <div className="col m-1 card text-dark bg-light p-3">
                      <h3>Sub Events</h3>
                      <div className="row">
                        {event.subEvents?.map((subEvent) => {
                          return (
                            <div key={subEvent.name}>
                              <div className="card mb-3">
                                <div className="d-flex card-header">
                                  {subEvent.name}{' '}
                                  {user && !subEvent.players.includes(user?.uid) && (
                                    <button
                                      className="btn btn-sm btn-secondary ms-auto"
                                      onClick={() => handleRegisterSubEvent(id, subEvent)}
                                    >
                                      Register
                                    </button>
                                  )}
                                  {subEvent.players.includes(user?.uid) && (
                                    <button
                                      className="btn btn-sm btn-secondary ms-auto"
                                      onClick={() => handleDeRegisterSubEvent(id, subEvent)}
                                    >
                                      Deregister
                                    </button>
                                  )}
                                </div>
                                {subEvent.players?.length > 0 && (
                                  <div className="d-flex justify-content-start p-2">
                                    {subEvent?.players?.map((player) => {
                                      return <PlayerInfo size={'sm'} player={player} key={player} />;
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            {/* <EventDiscussion event={event} /> */}
          </>
        )}
        <div
          className="modal fade"
          id="editEventModal"
          data-bs-backdrop="static"
          tabIndex="-1"
          aria-labelledby="editEventModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="editEventModal">
                  Edit Event
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form action="">
                  <input
                    type="text"
                    className="form-control m-1"
                    required
                    placeholder="Event Name*"
                    onChange={(e) => setEventName(e.target.value)}
                    value={eventName}
                  />
                  <PlacesAutocomplete
                    setEventLocation={setEventLocation}
                    setEventTown={setEventTown}
                    setVenueName={setVenueName}
                    clearInput={clearInput}
                    setClearInput={setClearInput}
                    venueName={venueName}
                  />
                  {/* <input
                  type="text"
                  className="form-control m-1"
                  required
                  placeholder="Event Location*"
                  onChange={(e) => setEventLocation(e.target.value)}
                  value={eventLocation}
                /> */}
                  <div className="row">
                    <div className="col">
                      <small className="ms-1 p-0">Start date</small>
                      <input
                        type="date"
                        className="form-control m-1"
                        required
                        placeholder="Event Start Date*"
                        onChange={(e) => {
                          const newStartDate = e.target.value;
                          const newEndDate = endDate ? (endDate < newStartDate ? newStartDate : endDate) : newStartDate;
                          setStartDate(newStartDate);
                          setEndDate(newEndDate);
                        }}
                        value={startDate}
                      />
                    </div>
                    <div className="col">
                      <small className="ms-1 p-0">End date</small>
                      <input
                        type="date"
                        className="form-control m-1"
                        required
                        placeholder="Event End Date*"
                        min={startDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        value={endDate}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control m-1"
                    placeholder="Team"
                    onChange={(e) => setTeam(e.target.value)}
                    value={team}
                  />
                  <div className="input-group m-1">
                    <span className="input-group-text" id="basic-addon3">
                      https://
                    </span>
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Event Website"
                      onChange={(e) => setEventWebsite(e.target.value)}
                      value={eventWebsite}
                    />
                  </div>

                  <div className="input-group m-1">
                    <span className="input-group-text" id="basic-addon3">
                      https://
                    </span>
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Tickets Site"
                      onChange={(e) => setTicketSite(e.target.value)}
                      value={ticketSite}
                    />
                  </div>

                  <div className="input-group m-1">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Sub event"
                      onChange={(e) => setNewSubEvents(e.target.value)}
                      value={newSubEvents}
                      ref={subEventInput}
                    />
                    <button onClick={handleAddSubEvent} className="btn btn-outline-secondary" type="button" id="button-addon2">
                      Add
                    </button>
                  </div>
                  <p>Current sub-events (click to remove):</p>
                  <ul className="list-group list-hover mb-3">
                    {subEvents.map((i) => {
                      return (
                        <li
                          className="text-align-start list-group-item list-group-item-action"
                          onClick={(e) => handleRemoveSubEvent(e)}
                          name={i.name || i}
                          key={i.name || i}
                        >
                          {i.name}
                        </li>
                      );
                    })}
                  </ul>
                  <textarea
                    rows="3"
                    className="form-control m-1"
                    placeholder="Notes"
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes}
                  />
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" onClick={clearForm} className="btn btn-secondary" data-bs-dismiss="modal">
                  Close
                </button>
                <button type="button" onClick={handleEditEvent} className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal centered id="attendanceModal" show={showLogisticsModal}>
          <Modal.Header>Modify Attendance</Modal.Header>
          <Modal.Body>
            <form>
              <div className="input-group m-1">
                <span className="input-group-text w-25" id="basic-addon3">
                  Arrival
                </span>
                <div className="w-75">
                  <Select
                    options={getDayOptions()}
                    placeholder="Arrival"
                    value={{ value: arrival, label: arrival }}
                    onChange={(e) => setArrival(e.value)}
                  />{' '}
                </div>
              </div>
              <div className="input-group m-1">
                <span className="input-group-text w-25" id="basic-addon3">
                  Sleeping
                </span>
                <div className="w-75">
                  <Select
                    onChange={(e) => setSleeping(e.value)}
                    value={{ value: sleeping, label: sleeping }}
                    options={[
                      { value: 'Tent', label: 'Tent' },
                      { value: 'Vehicle', label: 'Vehicle' },
                    ]}
                    placeholder="Sleeping"
                  />
                </div>
              </div>
              <div className="input-group m-1">
                <span className="input-group-text w-25" id="basic-addon3">
                  Transport
                </span>
                <div className="w-75">
                  <Select
                    onChange={(e) => setTransport(e.value)}
                    value={{ value: transport, label: transport }}
                    options={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]}
                    placeholder="Transport"
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" data-bs-dismiss="modal" onClick={() => handleToggleShowLogisicsModal()}>
              Close
            </Button>
            <Button variant="primary" type="submit" onClick={(e) => handleSubmitAttendanceData(e)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
