import { createContext, useReducer } from 'react';

export const AdminContext = createContext();

export const adminReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_ACCESS':
      return { ...state, admin: action.payload };
    default:
      return state;
  }
};

export function AdminContextProvider({ children }) {
  const [state, dispatch] = useReducer(adminReducer, {
    admin: 'not_admin',
  });

  const changeAdmin = (admin) => {
    dispatch({ type: 'CHANGE_ACCESS', payload: admin });
  };

  return <AdminContext.Provider value={{ ...state, changeAdmin }}>{children}</AdminContext.Provider>;
}
